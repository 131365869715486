import React from 'react'
import './header.css'
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header id='home'>
      <div className='container header__grid__container'>
        <div className="container header__container">
          <div>
            <h1 className='noselect'>Konstantin Mishukov</h1> 
            <h3 className='noselect'>Software Engineer & iOS Developer</h3>
            <CTA />
          </div>
        </div>
        <a href='#markov' className='noselect scroll__down'>Markov Algorithm</a>
        <HeaderSocials />
      </div>
    </header>
  )
}

export default Header