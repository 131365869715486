import React from 'react';
import './markov.css';
import AppStore from "../../assets/markov/appstore.svg";

// Markov Files
import Screen1 from '../../assets/markov/mobile/screen1.png';
import Screen2 from '../../assets/markov/mobile/screen2.png';
import Screen3 from '../../assets/markov/mobile/screen3.png';
import Screen4 from '../../assets/markov/mobile/screen4.png';
import Screen5 from '../../assets/markov/mobile/screen5.png';

import Policy from '../../assets/markov/privacy.pdf';

// Pagination
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const data = [
  {
    avatar: Screen1
  },
  {
    avatar: Screen2
  },
  {
    avatar: Screen3
  },
  {
    avatar: Screen4
  },
  {
    avatar: Screen5
  }
]

const Markov = () => {
  return (
    <section id='markov'>
      <div className='container markov__container_top'>
      <div className='markov__container'>
        <Swiper className='noselect markov__screenshots' 
          modules={[Pagination]}
          spaceBetween={40}
          slidesPerView={1}
          pagination={{ clickable: true, type: 'bullets' }}> {
            data.map(({avatar, name, review}, index) => {
              return (
              <SwiperSlide key={index} className='markov__screenshots'>
                  <img src={avatar} alt="" />
              </SwiperSlide>
              )
            })
         }
        </Swiper>
        <div className='about__content'>
          <h1 className='noselect'>Markov Algorithm</h1>
          <p className='noselect'>
          Students of computer science are probably familiar with Markov algorithm. In theoretical computer science, a Markov algorithm is a string rewriting system that uses grammar-like rules to operate on strings of symbols. Markov algorithms have been shown to be Turing-complete, which means that they are suitable as a general model of computation and can represent any mathematical expression from its simple notation. Markov algorithms are named after the mathematician Andrey Markov, Jr. <a href='https://en.wikipedia.org/wiki/Markov_algorithm'>- Wikipedia</a>
          </p>
          <p className='noselect'>
            <a href={Policy} download='markov_policy.pdf'>Privacy Policy Agreement</a>
          </p>
          <div className='noselect markov__buttons'>              
            <a className='appstore_container' href='https://apps.apple.com/us/app/markov-algorithm/id1427691412'>
              <img className='appstore' src={AppStore} alt="" /> 
            </a>
          </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Markov