import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
// import {BsTwitter} from 'react-icons/bs'
import {AiFillMediumCircle} from 'react-icons/ai'
import {RiThreadsLine} from 'react-icons/ri'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
      <a href='https://www.linkedin.com/in/kmishukov/' target="_blank" rel="noopener noreferrer"><BsLinkedin size={24} /></a>
      <a href='https://github.com/kmishukov' target="_blank" rel="noopener noreferrer"><BsGithub size={24} /></a>
      <a href='https://www.threads.net/@kmishukov' target="_blank" rel="noopener noreferrer"><RiThreadsLine size={28} /></a>
      {/* <a href='https://twitter.com/kmishukov' target="_blank" rel="noopener noreferrer"><BsTwitter size={24} /></a> */}
      <a href='https://medium.com/@kmishukov' target="_blank" rel="noopener noreferrer"><AiFillMediumCircle size={28}/></a>
    </div>
  )
}

export default HeaderSocials