import React from 'react'
import CV from '../../assets/main/cv.pdf'

const CTA = () => {
  return (
    <div className='CTA'>
        <a href={CV} download='mishukov_ios.pdf' className='btn'>Download CV</a>
        <a href='mailto:kmishukov.dev@gmail.com' className='btn btn-primary'>Let's Talk</a>
    </div>
  )
}

export default CTA